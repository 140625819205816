import React, { useState, useEffect } from "react";
import api from "../common/api";
import { formatBytes } from "../common/utils";
import Modal from 'react-modal';
import toast, { Toaster } from "react-hot-toast";
import QRCode from 'qrcode.react';
import Tips from "../common/tips";
import Switch from 'react-switch';
import Confirm from "../common/confirm";

function convertTimestamp(timestamp) {
  if (timestamp === 0) {
    return "永久有效"
  }
  // 创建一个新的Date对象，使用时间戳（以毫秒为单位）
  var date = new Date(timestamp * 1000);
  // 获取日期和时间的各个部分
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // JavaScript的月份是从0开始的，所以我们需要+1
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();
  // 将日期和时间格式化为YYYY-MM-DD HH:MM:SS格式
  var formattedTime =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);
  return formattedTime;
}

function Dashboard() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  //当前网址不需要目录，只需要域名即可
  // const [currentUrl] = useState(window.location.href.split("/").slice(0, 3).join("/"));
  const [userInfo, setUserInfo] = useState({});
  const [subLink, setSubLink] = useState({});
  const [nodeList, setNodeList] = useState([]);
  const [isQRCodeVisible, setQRCodeVisible] = useState(false);
  const [isAutoRenewEnabled, setIsAutoRenewEnabled] = useState(userInfo.autoRenew);
  const [onceTips, setOnceTips] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [isResetOpen, setResetOpen] = useState(false);
  const handleAutoRenewChange = async (checked) => {
    const resp = await api("post", "/api/user/renew", null);
    if (resp.status) {
      setIsAutoRenewEnabled(checked);
      // 更新本地缓存
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      storedUserInfo.renew = checked ? 1 : 0;
      localStorage.setItem('userInfo', JSON.stringify(storedUserInfo));
    } else {
      toast.error("自动续期设置失败");
    }
  };
  // 修改密码
  const handlePass = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const response = await api("post", "/api/user/password", formData);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.status) {
          toast.success("修改成功");
          setPassword(false);
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '/login';
          }, 1500);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  }
  const resetPlan = async () => {
    try {
      const response = await api("post", "/api/user/plan", { planId: userInfo.plan });
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.status) {
          getUserInfo();
          toast.success("重置成功");
          setResetOpen(false);
        } else {
          toast.error(response.message);
          setResetOpen(false);
        }
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      setResetOpen(false);
    }
  }
  const exToken = async () => {
    try {
      const response = await api("post", "/api/user/extoken", null);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.status) {
          getUserInfo();
          toast.success("修改成功");
          setConfirmOpen(false);
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  }
  const handleEmail = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const response = await api("post", "/api/user/email", formData);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.status) {
          toast.success("修改成功");
          setEmail(false);
          setUserInfo({ ...userInfo, email: formData.get('email') });
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  }
  const handleRecharge = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get('code') === "") {
      toast.error("请输入充值码");
      return;
    } else {
      // 去除空格
      formData.set('code', formData.get('code').replace(/\s+/g, ""));
    }
    const res = await api("post", "/api/user/recharge", formData);
    if (res.message === "401") {
      localStorage.clear();
      window.location.href = '/login';
    } else {
      if (res.status) {
        toast.success("充值成功");
        setRecharge(false);
        getUserInfo();
      } else {
        toast.error(res.message);
      }
    }
    setRecharge(false);
  }
  const Sign = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      console.log(formData.get('captcha'));
      const response = await api("get", "/api/user/sign?v=" + formData.get('captcha'), null);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.message === "签到成功") {
          toast.success("签到成功，获得" + formatBytes(response.data) + "流量");
          if (userInfo.able === 0) {
            getUserInfo();
          } else {
            setUserInfo({ ...userInfo, traffic: userInfo.traffic + response.data });
          }
        } else {
          toast.error(response.message);
        }
      }
      closeCaptcha();
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
      // 刷新验证码
      const captchaImage = document.getElementById("captcha");
      captchaImage.src = "/captcha?v=" + Math.random();
    }
  }
  const getUserInfo = async () => {
    try {
      const response = await api("post", "/api/user/info", null);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        localStorage.setItem('userInfo', JSON.stringify(response.data));
        //设置五分钟过期
        localStorage.setItem('userInfo_expire', Date.now() + 300000);
        setUserInfo(response.data);
        // setSubLink(`${currentUrl}/sub?token=${response.data.token}`);
        setSubLink(`https://cpdd.one/sub?token=${response.data.token}`);
        setIsAutoRenewEnabled(response.data.renew === 1);
        getNodes();
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  };
  const exAff = async () => {
    try {
      const response = await api("post", "/api/user/exaff", null);
      if (response.message === "401") {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        if (response.status) {
          getUserInfo();
          toast.success("已兑换为余额");
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("网络错误，请稍后再试");
      console.error(error);
    }
  }
  const getNodes = async () => {
    if (localStorage.getItem('node') === null || localStorage.getItem('node_expire') < Date.now()) {
      try {
        const response = await api("get", "/api/user/node", null);
        setNodeList(response.data);
        localStorage.setItem('node', JSON.stringify(response.data));
        // 设置两小时过期
        localStorage.setItem('node_expire', Date.now() + 7200000);
      } catch (error) {
        console.error(error);
      }
    } else {
      setNodeList(JSON.parse(localStorage.getItem('node')));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("regisrer")) {
      setOnceTips(true);
    }
    if (params.get('do') === 'charge') {
      setRecharge(true);
    }
    if (params.get('do') === 'sub') {
      openModal();
    }
    if (localStorage.getItem('userInfo') && localStorage.getItem('userInfo_expire') > Date.now()) {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      setUserInfo(storedUserInfo);
      setIsAutoRenewEnabled(storedUserInfo.renew === 1);
      setSubLink(`https://cpdd.one/sub?token=${storedUserInfo.token}`);
      getNodes();
    } else {
      getUserInfo();
    }
  }, []);
  // 验证码
  const [captcha, setCaptcha] = useState('');
  // 订阅
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // 套餐详情
  const [planDetail, setPlanDetail] = useState(false);
  // 邀请
  const [recom, setRecom] = useState(false);
  // 充值
  const [recharge, setRecharge] = useState(false);
  // 修改密码
  const [password, setPassword] = useState(false);
  // 修改邮箱
  const [email, setEmail] = useState(false);
  function openCaptcha() {
    setCaptcha(true);
    document.getElementById('captcha').src = `/captcha?v=${Math.random()}`
  }
  function closeCaptcha() {
    setCaptcha(false);
  }
  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  function copy(url) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    var textArea = document.createElement("textarea");
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? '复制成功' : '复制失败';
      toast.success(msg);
    } catch (err) {
      toast.error('无法复制');
    }
    document.body.removeChild(textArea);
    // 复制操作完成后，将滚动位置设置回原来的位置
    window.scrollTo(0, scrollPosition);
  }
  Modal.setAppElement('#root')
  return (
    <>
      <Tips msg="加入TG群吹水领福利" title="加入群聊" link="https://t.me/vpnpn123" />
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-3xl mx-auto">
          <Toaster />
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">用户信息</h2>
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">用户名</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userInfo.username}</div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">邮箱</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userInfo.email || '待绑定'}</div>
                      <button onClick={() => { setEmail(true) }} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        修改
                      </button>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">余额</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userInfo.credit}{userInfo.aff > 0 ? ` + ` : null}<span className="text-red-600">{userInfo.aff > 0 ? userInfo.aff : null}</span>￥</div>
                      <button onClick={() => setRecharge(true)} type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        充值
                      </button>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <button onClick={openCaptcha} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">每日签到</button>
                    <button onClick={() => setRecom(true)} className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">邀请好友</button>
                    <button onClick={() => setPassword(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">修改密码</button>
                  </div>
                </dl>
              </div>
            </div>

          </div>
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <Confirm
              isOpen={isResetOpen}
              title="确认重置"
              onClose={() => setResetOpen(false)}
              onConfirm={resetPlan}
            >
              您确认要重置套餐吗？<br />
              这会导致您重新订购当前套餐。<br />
            </Confirm>
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">订阅信息</h2>
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  {userInfo.able === 1 ? (
                    <>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">总流量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{formatBytes(userInfo.traffic)}</div>
                          <a href="/plan" className="font-semibold text-indigo-600 hover:text-indigo-500">
                            {userInfo.able === 0 ? '购买套餐' : '更换套餐'}
                          </a>
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">已用流量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{formatBytes(userInfo.trafficked)}</div>
                          {userInfo.plan !== 0 &&
                            (
                              <button onClick={() => setResetOpen(true)} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                重置套餐
                              </button>
                            )}
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">速率限制</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{userInfo.plan === 0 && userInfo.traffic < 50 * 1073741824 ? "50Mbps" : "不限速"}</div>
                        </dd>
                      </div>
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">设备数量</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">不限制</div>
                        </dd>
                      </div>
                      {userInfo.plan !== 0 && userInfo.expired !== 0 && (
                        <div className="pt-6 sm:flex">
                          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">自动续费</dt>
                          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <Switch
                              onChange={handleAutoRenewChange}
                              checked={isAutoRenewEnabled}
                              offColor="#f56565"
                              onColor="#48bb78"
                            />
                          </dd>
                        </div>
                      )}
                      <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">到期时间</dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                          <div className="text-gray-900">{convertTimestamp(userInfo.expired)}</div>
                        </dd>
                      </div>
                      <div className="bg-gradient-to-r bg-gray-200 h-2 rounded-lg mb-2">
                        <div
                          className="bg-indigo-600 h-full rounded-lg"
                          style={{
                            width: `${((userInfo.traffic - userInfo.trafficked) /
                              userInfo.traffic) *
                              100
                              }%`,
                          }}
                        />
                      </div>
                      <div className="flex flex-wrap gap-2">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={openModal}>
                          订阅 [导入节点]
                        </button>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setConfirmOpen(true)}>
                          修改订阅地址
                        </button>
                        {userInfo.plan !== 0 && (
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setPlanDetail(true)}
                          >
                            查看套餐详情
                          </button>
                        )}
                      </div>
                      <>
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          contentLabel="订阅选项"
                          style={{
                            overlay: {
                              backgroundColor: 'rgba(0, 0, 0, 0.75)'
                            },
                            content: {
                              top: '50%',
                              left: '50%',
                              right: 'auto',
                              bottom: 'auto',
                              marginRight: '-50%',
                              transform: 'translate(-50%, -50%)',
                              backgroundColor: '#fff',
                              borderRadius: '8px',
                              padding: '30px',
                              maxWidth: '600px',
                              width: '90%'
                            }
                          }}
                        >
                          <h2 className="mb-6 text-2xl font-semibold">订阅方式</h2>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <button onClick={() => copy(subLink)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">复制网址</button>
                            <button onClick={() => setQRCodeVisible(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">查看二维码</button>
                            <button
                              onClick={() => {
                                let processedSubLink = btoa(subLink + "&flag=shadowrocket");
                                window.location.href = `shadowrocket://add/sub://${processedSubLink}?remark=${encodeURIComponent("冲上云霄")}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Shadowrocket
                            </button>
                            <button
                              onClick={() => {
                                let encodeurl = encodeURIComponent(subLink + "&flag=clash");
                                window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent("冲上云霄")}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Clash
                            </button>
                            <button
                              onClick={() => {
                                let encodeurl = encodeURIComponent(subLink);
                                window.location.href = `clash://install-config?url=${encodeurl}&name=${encodeURIComponent("冲上云霄")}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Clash Meta
                            </button>
                            <button
                              onClick={() => {
                                let encodeurl = encodeURIComponent(subLink);
                                window.location.href = `stash://install-config?url=${encodeurl}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Stash
                            </button>
                            <button
                              onClick={() => {
                                let encodeurl = encodeURIComponent("https://sb.vpnpn.com/sub?sub=" + subLink + "#冲上云霄");
                                window.location.href = `sing-box://import-remote-profile?url=${encodeurl}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Sing-Box
                            </button>
                            <button
                              onClick={() => {
                                let encodeurl = encodeURIComponent("https://sub.vpnpn.com/sub?target=surge&ver=4&url=" + encodeURIComponent(subLink) + "&insert=false&emoji=true&list=false&tfo=false&scv=true&fdn=false&expand=true&sort=false");
                                window.location.href = `surge://install-config?url=${encodeurl}&name=${encodeURIComponent("冲上云霄")}`;
                              }}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              导入Surge
                            </button>
                          </div>
                        </Modal>
                      </>
                      {
                        isQRCodeVisible && ( // 如果模态框可见，则渲染模态框和二维码
                          <>
                            <Modal
                              isOpen={isQRCodeVisible}
                              onRequestClose={() => setQRCodeVisible(false)} // 当请求关闭模态框时，设置模态框为不可见
                              style={{
                                overlay: {
                                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                },
                                content: {
                                  top: '50%',
                                  left: '50%',
                                  right: 'auto',
                                  bottom: 'auto',
                                  marginRight: '-50%',
                                  transform: 'translate(-50%, -50%)',
                                  backgroundColor: '#fff',
                                  borderRadius: '4px',
                                  padding: '20px'
                                }
                              }}
                            >
                              <div
                                className="bg-white p-8 rounded-lg relative flex flex-col items-center"
                              >
                                <QRCode value={subLink} />
                              </div>
                            </Modal>
                          </>
                        )}
                      <Confirm
                        isOpen={isConfirmOpen}
                        title="确认修改"
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={exToken}
                      >
                        您确认要修改订阅地址吗？<br />
                        这会导致您的订阅地址变更。<br />
                        原有订阅地址将失效，需要重新导入节点。
                      </Confirm>
                    </>
                  ) : (
                    <div className="text-center">
                      {userInfo.trafficked >= userInfo.traffic ? (
                        <>
                          <h3 className="mt-8 text-lg font-semibold text-gray-900">流量耗尽</h3>
                          <p className="text-sm text-gray-600">{formatBytes(userInfo.traffic)}/{formatBytes(userInfo.trafficked)}</p>
                        </>
                      ) : (
                        <>
                          <h3 className="mt-8 text-lg font-semibold text-gray-900">套餐过期</h3>
                          <p className="text-sm text-gray-600">{convertTimestamp(userInfo.expired)}</p>
                        </>
                      )}
                      <p className="mt-2 text-sm text-gray-600">请签到或购买套餐以使用服务。</p>
                      <a href="/plan" className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        购买套餐
                      </a>
                      {userInfo.trafficked >= userInfo.traffic && userInfo.plan !== 0 &&
                        (
                          <>
                            <button onClick={() => setResetOpen(true)} className="m-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                              重置套餐
                            </button>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold mx-2 py-2 px-4 rounded" onClick={() => setPlanDetail(true)}>查看套餐详情</button>
                          </>
                        )}
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>

          <>
            <Modal
              isOpen={email}
              onRequestClose={() => setEmail(false)}
              contentLabel="修改邮箱"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }
              }}>
              <form onSubmit={handleEmail}>
                <h2 className="text-2xl font-semibold mb-4">修改邮箱</h2>
                <p className="mb-4 text-gray-600">邮箱将用于找回密码和接收套餐到期通知。</p>
                <input
                  name="email"
                  placeholder="请输入新邮箱"
                  type="email"
                  className="w-full border border-gray-300 rounded-md p-2 mb-4"
                />
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  修改
                </button>
              </form>
            </Modal>
          </>
          <>
            <Modal
              isOpen={password}
              onRequestClose={() => setPassword(false)}
              contentLabel="修改密码"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                  padding: '20px'
                }
              }}>
              <form
                onSubmit={handlePass}
              >
                <h2 className="text-2xl font-semibold mb-4">修改密码</h2>
                <input name="opass" placeholder="请输入原密码" type="password" className="w-full border border-gray-300 rounded-md p-2" />
                <input name="pass" placeholder="请输入新密码" type="password" className="w-full border border-gray-300 rounded-md p-2 mt-2" />
                <input name="rpass" placeholder="请再次输入新密码" type="password" className="w-full border border-gray-300 rounded-md p-2 mt-2" />
                <button type="submit" className="my-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">修改</button>
              </form>
            </Modal>
          </>
          <>
            <Modal
              isOpen={recharge}
              onRequestClose={() => setRecharge(false)}
              contentLabel="余额充值"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  maxWidth: '400px',
                  width: '100%'
                }
              }}>
              <form onSubmit={handleRecharge}>
                <h2 className="text-2xl font-semibold mb-6 text-center">余额充值</h2>
                <input
                  placeholder="请输入充值码"
                  name="code"
                  type="text"
                  className="w-full border border-gray-300 rounded-md p-3 mb-4"
                />
                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="w-full mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    兑换
                  </button>
                  <a
                    target="_blank"
                    href="https://shop.vpnpn.com/"
                    className="w-full ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-center"
                  >
                    购买充值码
                  </a>
                </div>
              </form>
            </Modal>
          </>
          <>
            <Modal
              isOpen={planDetail}
              onRequestClose={() => setPlanDetail(false)}
              contentLabel="套餐详情"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  maxWidth: '500px',
                  width: '90%'
                }
              }}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">套餐详情</h2>
                <button onClick={() => setPlanDetail(false)} className="text-gray-500 hover:text-gray-700">
                  &times;
                </button>
              </div>
              {userInfo.plan_detail ? (
                <>
                  <div className="mb-4 flex justify-between">
                    <div className="text-lg font-medium text-gray-900">套餐名称</div>
                    <div className="text-sm text-gray-500">{userInfo.plan_detail.name}</div>
                  </div>
                  <div className="mb-4 flex justify-between">
                    <div className="text-lg font-medium text-gray-900">套餐流量</div>
                    <div className="text-sm text-gray-500">{formatBytes(userInfo.plan_detail.traffic)}</div>
                  </div>
                  <div className="mb-4 flex justify-between">
                    <div className="text-lg font-medium text-gray-900">套餐价格</div>
                    <div className="text-sm text-gray-500">{userInfo.plan_detail.price}￥</div>
                  </div>
                  <div className="mb-4 flex justify-between">
                    <div className="text-lg font-medium text-gray-900">套餐时长</div>
                    <div className="text-sm text-gray-500">{userInfo.plan_detail.days === 0 ? "不限时长" : userInfo.plan_detail.days + "天"}</div>
                  </div>
                </>
              ) : (
                <div className="text-sm text-gray-500">无法获取套餐详情</div>
              )}
            </Modal>
          </>
          <>
            <Modal
              isOpen={onceTips}
              onRequestClose={() => setOnceTips(false)}
              contentLabel="使用须知"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  maxWidth: '500px',
                  width: '90%'
                }
              }}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">使用须知</h2>
                <button onClick={() => setOnceTips(false)} className="text-gray-500 hover:text-gray-700">
                  &times;
                </button>
              </div>
              <div className="space-y-1">
                <ul className="list-disc list-inside text-lg font-medium text-gray-900">
                  <li>每日签到可获得0-1GB流量</li>
                  <li>签到或购买套餐后会出现订阅按钮</li>
                  <li>未购买套餐用户将会进行限速处理</li>
                  <li>节点全超时需等待三分钟同步信息</li>
                  <li>建议绑定邮箱以接收服务通知</li>
                  <li>节点速度【专线&gt;隧道&gt;直连】</li>
                </ul>
              </div>
              <button
                onClick={() => {
                  setOnceTips(false);
                  localStorage.removeItem('regisrer');
                }}
                className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
              >
                我已知晓
              </button>
            </Modal>
          </>
          <>
            <Modal
              isOpen={recom}
              onRequestClose={() => setRecom(false)}
              contentLabel="邀请好友"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  maxWidth: '500px',
                  width: '90%'
                }
              }}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">邀请好友</h2>
                <button onClick={() => setRecom(false)} className="text-gray-500 hover:text-gray-700">
                  &times;
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <div className="text-lg font-medium text-gray-900">您的邀请链接</div>
                  <div className="text-sm text-gray-500">{`https://cpdd.one/?r=${userInfo.id}`}</div>
                </div>
                <div>
                  <div className="text-lg font-medium text-gray-900">邀请规则</div>
                  <div className="text-sm text-gray-500">被邀请者注册可获得1GB不限时流量</div>
                  <div className="text-sm text-gray-500">您可获得被邀请者每次充值金额的{userInfo.aff_rate}%作为奖励</div>
                  <div className="text-sm text-gray-500">奖励金额满100可联系客服进行USDT或支付宝提现</div>
                </div>
                <div>
                  <div className="text-lg font-medium text-gray-900">邀请奖励</div>
                  <div className="text-sm text-gray-500">已获得奖励：{userInfo.aff}￥</div>
                </div>
              </div>
              <div className="mt-6 flex justify-between">
                <button onClick={() => copy(`https://cpdd.one/?r=${userInfo.id}`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mr-2">复制链接</button>
                <button onClick={exAff} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full ml-2">兑换为余额</button>
              </div>
            </Modal>
          </>
          <>
            <Modal
              isOpen={captcha}
              onRequestClose={() => setCaptcha(false)}
              contentLabel="签到"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  padding: '30px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }
              }}>
              <h2 className="text-2xl font-semibold mb-4 text-center">签到</h2>
              <div className="text-sm text-gray-500 mb-1">签到状态每日八点重置</div>
              <div className="text-sm text-gray-500 mb-1">每日签到可获得0-1GB流量</div>
              <div className="text-sm text-gray-500 mb-3">可绑定TG Bot(@vpnpn_bot)自助签到</div>
              <form onSubmit={Sign}>
                <img
                  src={`/captcha`}
                  id="captcha"
                  onClick={() => {
                    document.getElementById('captcha').src = `/captcha?v=${Math.random()}`
                  }}
                  className="w-full border border-gray-300 rounded-md p-2 mb-4"
                />
                <input
                  name="captcha"
                  placeholder="请输入验证码"
                  type="text"
                  className="w-full border border-gray-300 rounded-md p-2 mb-4"
                />
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  签到
                </button>
              </form>
            </Modal>
          </>
          <h2 className="text-2xl font-semibold mb-4">可用节点</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {nodeList && nodeList
              .sort((a, b) => a.rate - b.rate)
              .map((node, index) => (
                <div key={index} className="bg-white shadow-md rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-blue-500"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform: 'translateY(-15%)' }}
                        >
                          <path d="M3.5 13a4.5 4.5 0 118.94-2.03A4 4 0 0114 18H4a3.5 3.5 0 01-.5-7z" />
                        </svg>
                      </div>
                      <div className="ml-4">
                        <div className="text-lg font-medium text-gray-900 truncate max-w-xs">{node.name.replace('中转', '')}</div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">{node.rate}x</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div >
    </>
  );
}

export default Dashboard;
